import { StoryCompass } from '@/components/UI/StoryCompass/StoryCompass'
import { Indicator } from '@/types'
import React from 'react'
import Image from 'next/image'
import { Button } from '@/components/UI/Button/Button'
import { Accordion } from '@/components/UI/Accordion/Accordion'
import { Status } from '@/components/UI/Status/Status'
import { displayMetric } from '@/utils/displayMetric'

interface IndicatorPanelProps {
    indicator: Indicator
    compassGroupIndicators: Indicator[]
    compassIndex: number
}

const STATUS_TEXT_COLOUR = {
    Healthy: 'text-healthy',
    Unhealthy: 'text-unhealthy',
    'Very Unhealthy': 'text-veryUnhealthy',
}

export const IndicatorPanel = ({ indicator, compassGroupIndicators = [], compassIndex }: IndicatorPanelProps) => {
    if (!indicator) {
        return null
    }

    const {
        compass,
        label,
        status,
        body,
        metricDescription,
        dataSourceUrl,
        subIndicators,
        dataMethodology,
    } = indicator

    const indicatorStatusTextColour = STATUS_TEXT_COLOUR[status]

    return (
        <div className="relative pb-16 lg:border-l-4 lg:border-darkGreen-60 lg:pb-28">
            <div className="relative h-[258px]">
                <Image className="size-full object-cover" src="/images/data-tool/indicator-panel/ecology.png" alt="" width={ 595 } height={ 250 } />

                {/* Bottom gradient on image */}
                <div className="indicator-panel-gradient absolute inset-x-0 bottom-[-2px] h-[89px]" />
            
                <div className="absolute inset-x-[38px] bottom-[32px]">
                    <div className="inline-flex flex-row items-center gap-2 rounded-[32px] bg-darkGreen-0.6 py-2 pl-2 pr-6 backdrop-blur-sm">
                        <div className="size-[40px]">
                            <StoryCompass index={ compassIndex } />
                        </div>
                        <p className="headline">{compass}</p>
                    </div>
                </div>
            </div>

            {/* Content */}
            <div className="mt-2 flex flex-col gap-12 px-6 lg:gap-16 lg:px-12">
                <div>
                    <h3>{label}</h3>
                    <p className={ `h3 ${ indicatorStatusTextColour }` }>{status}</p>

                    {!!body && (<p className="mt-6">{body}</p>)}
                </div>

                {/* Indicators are made up of 1 or more subIndicators */}
                <div>
                    {/* If there's only 1 subIndicator, use that to show the metrics and labels */}
                    {subIndicators.length <= 1 && (
                        <>
                            <p className="mb-4 font-bold">{metricDescription}</p>

                            <p 
                                className={ `h4 block ${ indicatorStatusTextColour }` }
                                aria-label={ subIndicators[0].metric.toString() }
                            >
                                {displayMetric(subIndicators[0].metric)}
                            </p>

                            {metricDescription !== subIndicators[0].label && (
                                <p className="body-3 mt-2">{subIndicators[0].label}</p>
                            )}
                        </>
                    )}
                    
                    {/* Only show subIndicators if there's more than 1 */}
                    {subIndicators.length > 1 && (
                        <>
                            <p className="mb-4 font-bold">{metricDescription}</p>

                            <ul className="grid grid-cols-2 gap-x-4 gap-y-12">
                                {subIndicators.map((subIndicator) => {
                                    const subIndicatorStatusTextColour = STATUS_TEXT_COLOUR[subIndicator.status]

                                    return (
                                        <li key={ `sub${ subIndicator.id }` }>
                                            <span
                                                className={ `h4 mb-2 block ${ subIndicatorStatusTextColour } break-words` }
                                                aria-label={ subIndicator.metric.toString() }
                                            >
                                                {displayMetric(subIndicator.metric)}
                                            </span>

                                            <span className="body-3 block text-white">{subIndicator.label}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </>
                    )}
                </div>

                <hr className="rounded-md border-t-[3px] border-darkGreen-80" />

                <div className="">
                    <div className="mb-6">
                        <p className="font-bold">About the data</p>
                        <small className="body-3">Data measured at a regional level</small>
                    </div>

                    {dataSourceUrl && (
                        <Button className="mb-4" openInNewTab withBorder isBold onClick={ dataSourceUrl }>
                            Data source
                        </Button>
                    )}

                    {!!dataMethodology && (
                        <div className="rounded-lg bg-darkGreen-100">
                            <Accordion title="Data methodology" withBorder>
                                <div className="richText">
                                    <div dangerouslySetInnerHTML={{ __html: dataMethodology }} />
                                </div>
                            </Accordion>
                        </div>
                    )}
                </div>

                {/* Other indicators in that compass group */}
                {compassGroupIndicators.length > 1 && (
                    <>
                        <hr className="rounded-md border-t-[3px] border-darkGreen-80" />

                        <div>
                            <p className="mb-4 font-bold">All {compass} indicators</p>

                            <div className="grid grid-cols-3 gap-2">
                                {compassGroupIndicators.map((groupIndicator) => (
                                    <Status key={ groupIndicator.slug } isCard isSmall label={ groupIndicator.label } status={ groupIndicator.status } />
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
