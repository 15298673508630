'use client'

import RenderComponents from '@/components/RenderComponents'
import { RenderCmsImage } from '@/components/UI/RenderCmsImage/RenderCmsImage'
import { ImageType, WagtailPageType } from '@/types'
import React from 'react'
import DOMPurify from 'isomorphic-dompurify'
// import * as Sentry from "@sentry/nextjs"

interface HomePageProps extends WagtailPageType {
    heading_one: string
    heading_two: string
    image: ImageType
    body: string // RichText
}

export const HomePage = (props: HomePageProps) => {
    const {
        content,
        image,
        heading_one,
        heading_two,
        body,
    } = props

    const title = heading_one?.replace(/{{/gm, '<span class="text-energy">').replace(/}}/gm, '</span><br />')

    return (
        <div className="isLightHeader">
            <div className="relative -mt-16 pt-16">
                {image && (
                    <RenderCmsImage
                        className="absolute inset-0 z-[-1] bg-darkGreen-100 object-cover"
                        src={ image.large.src }
                        fill
                        alt={ image.alt }
                    />
                )}

                <div className="container py-12 lg:py-16">
                    <h1 className="text-shadow text-white" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }} />
                </div>
            </div>

            {/* <button onClick={ () => {
                Sentry.captureException("Test test test")

                console.log(Sentry.SDK_VERSION)
                console.log("process.env.NEXT_PUBLIC_SENTRY_DSN", process.env.NEXT_PUBLIC_SENTRY_DSN)
                
                throw new Error("TEST TEST TEST")
            } }>
                CREATE A SENTRY ERROR
            </button> */}

            <div className="isLight container grid grid-cols-2 gap-6 pb-6 pt-10 lg:gap-20 lg:pb-10 lg:pt-20">
                <h2 className="h2-alt col-span-full md:col-auto">{heading_two}</h2>

                <div className="richText col-span-full md:col-auto" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }} />
            </div>

            <div className="isLight">
                <RenderComponents content={ content } />
            </div>
        </div>
    )
}
