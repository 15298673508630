import classNames from 'classnames'
import React from 'react'

const environment = process.env.NODE_ENV
const isProduction = environment === 'production'

interface DebugProps {
    className?: string
    children: React.ReactNode
}

/**
 * Used to wrap debug controls or show debug content while in local development or staging environments.
 *
 * E.G. It's been used to show a debug button at the bottom of forms which fills out all the input fields on a form so we
 * don't have to fill it out manually each time.
 */
export const Debug = ({ children, className }: DebugProps) => {
    if (isProduction) {
        return null
    }

    return (
        <div className={ classNames('m-4 p-4 flex flex-col items-start gap-8 border-8', className) }>
            <p>Debug [{environment}]</p>

            {children}
        </div>
    )
}
