import { StoryPageProps } from "@/components/Pages/StoryPage/StoryPage"

export const mockChristchurchStoryPage: Omit<StoryPageProps, 'location' | 'compassGraphData'> = {
    "id": 5,
    "meta": {
        "type": "app.StoryPage",
        "detail_url": "https://he-puna-ora.stage.octave.nz/api/v2/pages/5/",
        "html_url": "https://he-puna-ora.stage.octave.nz/christchurch/",
        "slug": "christchurch",
        "seo_title": "",
        "search_description": "",
        "first_published_at": "2024-08-14T09:54:38.011948+12:00",
        "locale": "en",
        "url": "/christchurch/",
    },
    "title": "Christchurch",
    categories: [],
    heading_one: "Explore Christchurch’s {{urban wellbeing}}",
    body: "Our Mauri Ora compass is a framework to measure urban wellbeing. Backed by data, it holistically assesses wellbeing across five interconnected areas: architecture, urban, economy, energy and ecology. Something about how we’re using Christchurch data. The compass holistically assesses wellbeing across five interconnected areas: architecture, urban, economy, energy and ecology. ",
    location_title: "Christchurch",
    mauri_indicators_api: "",
    image: null,
    data_tool_page: "",
    cta_heading: "Explore {{Christchurch’s}} urban wellbeing ",
    cta_body: "A few lines to introduce the data tool and how it connects with the compass. Probably two or three lines of text at least. Maybe even four or five lines of text will be needed to support this call to action.",
}