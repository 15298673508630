'use client'

import classNames from "classnames"
import React from "react"
import ChevronSVG from './chevron.svg'

interface AccordionProps {
    title: string
    subText?: string
    children: React.ReactNode
    withBorder?: boolean
    noBorder?: boolean
    noPadding?: boolean
    defaultIsOpen?: boolean
    className?: string
}

export const Accordion = ({title, children, withBorder, subText, noBorder, noPadding, defaultIsOpen = false, className}: AccordionProps) => {
    const [isOpen, setIsOpen] = React.useState(defaultIsOpen)

    return (
        <div className={ classNames("", withBorder ? "border border-darkGreen-70 rounded-[8px]" : noBorder ? "" : "border-b-2 border-darkGreen-90 first:border-t-2") }>
            <button 
                type="button"
                aria-expanded={ isOpen }
                aria-controls={ `body-${ title }` }
                onClick={ () => setIsOpen(!isOpen) }
                id={ `btn-${ title }` }
                className={ classNames('flex flex-col gap-1 text-left w-full lg:hover:text-ecology', noPadding ? "" : withBorder ? "p-4 " : "py-6", className) }
            >
                <span className="flex w-full flex-row items-center justify-between gap-2">
                    <span className="body-1 font-bold">{title}</span>

                    <ChevronSVG className={ classNames("transition-transform w-4 h-4", isOpen ? "rotate-180" : "") } />
                </span>

                {subText && <span className={ (classNames("body-2" /*withBorder ? "pr-14" : "pr-8"*/)) }>{subText}</span>}
            </button>

            <div
                hidden={ !isOpen }
                id={ `body-${ title }` }
                role="region"
                aria-labelledby={ `btn-${ title }` }
                className={ classNames("grid transition-[grid-template-rows] duration-200", isOpen ? "grid-rows-[1fr]" : "grid-rows-[0fr]") }
            >
                <div className="overflow-hidden">
                    <div className={ classNames(noPadding ? "" : withBorder ? 'px-4 pb-4' : 'pb-6') }>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}