import type { BlockType } from '@/types'
import { AccordionBlock } from '@/components/Blocks/AccordionBlock/AccordionBlock'
import { ImageAndTextBlock } from '@/components/Blocks/ImageAndTextBlock/ImageAndTextBlock'
import { RichTextBlock } from '@/components/Blocks/RichTextBlock/RichTextBlock'
import { TwoColumnBlock } from '@/components/Blocks/TwoColumnBlock/TwoColumnBlock'
import TempBlock from '@/components/Blocks/TempBlock'
import { Debug } from './UI/Debug/Debug'
import { LinkBlock } from '@/components/Blocks/LinkBlock/LinkBlock'
import { TilesBlock } from '@/components/Blocks/TilesBlock/TilesBlock'
import { HeroBlock } from '@/components/Blocks/HeroBlock/HeroBlock'

export const containerClassList: Record<BlockType['container'], string> = {
    standard: 'container',
    center: 'container text-center',
    bare: '',
}

/**
 * List of components (Blocks) that can be rendered on the FE
 * Should have an equivalent wagtail block
 */
const COMPONENTS_LIST = {
    HeroBlock,
    AccordionBlock,
    ImageAndTextBlock,
    LinkBlock,
    rich_text: RichTextBlock,
    RichText: RichTextBlock,
    TilesBlock,
    TwoColumnBlock,
}

/**
 * Handles rendering different Wagtail Blocks as React components
 */
export default function RenderComponents({ content = [], isNested }: { content: BlockType[], isNested?: boolean }) {
    if (content.length === 0) {
        return <Debug>No content on this page.</Debug>
    }

    return content.map((block, key) => {
        const {
            type,
            id,
        } = block

        let container: BlockType['container'] = block.container || 'standard'

        if (type === 'HeroBlock') {
            container = 'bare'
        }

        const Block = COMPONENTS_LIST[type] || TempBlock
        const wrapperClasses = isNested ? '' : containerClassList[container]

        return (
            <section key={ `${ id }-${ key }` } id={ id } className={ wrapperClasses }>
                <Block { ...block } isNested />
            </section>
        )
    })
}
