'use client'

import { ScrollToButton } from '@/components/UI/ScrollToButton/ScrollToButton'
import { CompassGraphDataV2, ImageTypeAlt, StoryPageData, WagtailPageType } from '@/types'
import React from 'react'
import { StorySection } from './StorySection/StorySection'
import { Button } from '@/components/UI/Button/Button'
import { Indicators } from './_components/Indicators/Indiciators'
import { CompassGraph } from '../DataCompassPage/_components/CompassGraph/CompassGraph'
import { PRIVATE_BASE_URL } from '@/utils/api'
import DOMPurify from 'isomorphic-dompurify'
import { RenderCmsImage } from '@/components/UI/RenderCmsImage/RenderCmsImage'

export interface StoryPageProps extends WagtailPageType {
    title: string
    compassGraphData: CompassGraphDataV2
    heading_one: string
    body: string // RichText
    image: ImageTypeAlt
    location_title: string
    location_title_te_reo?: string
    categories: StoryPageData[]
    cta_heading: string
    cta_body: string // RichText
    mauri_indicators_api: string
    data_tool_page: string
}

export const StoryPage = ({compassGraphData, data_tool_page = '', categories = [], image, cta_body, cta_heading, body, heading_one, location_title }: StoryPageProps) => {
    const [activeIndex, setActiveIndex] = React.useState<boolean[]>(categories.map((story, index) => index === 0))
    const urlToDataTool = data_tool_page.replace(PRIVATE_BASE_URL, "")
    
    const headingOne = heading_one?.replace(/{{/gm, '<span class="text-ecologyWhite">').replace(/}}/gm, '</span>')
    const ctaTitle = cta_heading?.replace(/{{/gm, '<span class="text-energy">').replace(/}}/gm, '</span><br />')

    return (
        <>
            <div className="isLight -mt-16 pt-16 lg:pt-4">
                <section className="container top-0 grid grid-cols-12 items-center pb-32 lg:sticky lg:min-h-screen lg:pb-0">
                    <div className="col-[4_/_-4] mb-8 lg:col-span-6 lg:mb-0">
                        <RenderCmsImage src={ image.url } width={ image.width } height={ image.height } alt={ image.alt } sizes="(max-width: 768px) 100vw, 50vw" />
                    </div>

                    <div className="col-span-full flex flex-col gap-8 lg:col-span-5 lg:col-start-8">
                        <h1 className="h2 text-darkGreen-100" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(headingOne) }} />

                        <div className="flex flex-col gap-4 text-darkGreen-80" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }} />

                        {categories.length > 0 && categories[0].id && (
                            <div className="text-ecologyWhite">
                                <ScrollToButton id={ categories[0].id } />
                            </div>
                        )}
                    </div>
                </section>

                <div className="relative">
                    {categories.map((story, index) => {
                        return (
                            <StorySection
                                location={ location_title }
                                title={ story.title }
                                title_te_reo={ story.title_te_reo }
                                key={ story.id }
                                id={ story.id }
                                body={ story.body }
                                indicators={ story.indicators }
                                image={ story.image }
                                setInView={ (inView) => {
                                    const newActiveIndex = [...activeIndex]
                                    const isAlreadyInView = newActiveIndex[index]

                                    if (isAlreadyInView === inView) {
                                        return
                                    } 

                                    newActiveIndex[index] = inView
                                    setActiveIndex(newActiveIndex)
                                } }
                                index={ index }
                                urlToDataTool={ urlToDataTool }
                            />
                        )
                    })}

                    <div className="pointer-events-none absolute inset-0 hidden lg:block">
                        <div className="sticky top-0 z-10 h-screen">
                            <Indicators data={ categories } activeIndex={ activeIndex } />
                        </div>
                    </div>
                </div>
            </div>

            <section className="bg-darkGreen-100 text-white">
                <div className="container py-10 lg:py-20">
                    <div className="grid gap-8 rounded-[24px] border border-darkGreen-80 bg-darkGreen-90 px-6 py-10 shadow-[0px_4px_10px_0px_rgba(0,_0,_0,_0.15)] lg:grid-cols-12 lg:gap-0 lg:py-20">
                        <div className="col-span-full flex flex-col gap-8 lg:col-[2_/_6]">
                            <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(ctaTitle) }} />

                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cta_body) }} />

                            <Button className="self-start" isBold withBorder onClick={ urlToDataTool }>
                                Explore the data tool
                            </Button>
                        </div>

                        {/* Compass image */}
                        <div className="relative col-span-full min-h-[300px] lg:col-[7_/_-2]">
                            <CompassGraph
                                data={ compassGraphData }
                                // location={ location }
                                isReadOnly
                                dataCompassPageUrl=""
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
