'use client'

import { Button } from '@/components/UI/Button/Button'
import { Status } from '@/components/UI/Status/Status'
import { StoryCompass } from '@/components/UI/StoryCompass/StoryCompass'
import { ImageTypeAlt, Indicator } from '@/types'
import DOMPurify from 'isomorphic-dompurify'
import React from 'react'
import { motion, motionValue, useInView, useScroll, useTransform } from 'framer-motion'
import { usePrefersReducedMotion } from '@/utils/usePrefersReducedMotion'
import { RenderCmsImage } from '@/components/UI/RenderCmsImage/RenderCmsImage'
import classNames from 'classnames'
import styles from './StorySection.module.scss'

interface StorySectionProps {
    id: string
    title: string
    title_te_reo: string
    body: string
    location: string
    urlToDataTool: string
    indicators: Indicator[]
    image: {
        large: ImageTypeAlt
    }
    setInView: (inView: boolean) => void
    index: number
}

export const StorySection = ({title, body, location, urlToDataTool, indicators = [], image, id, setInView, title_te_reo, index}: StorySectionProps) => {
    const ref = React.useRef<HTMLDivElement>(null)
    const prefersReducedMotion = usePrefersReducedMotion()
    
    const isInView = useInView(ref, { amount: 0.5 })

    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start end", "end end"],
    })

    const yProgress = prefersReducedMotion ? motionValue(1) : scrollYProgress

    const opacityTitle = useTransform(
        yProgress,
        [0, 0.3, 0.6],
        [0, 0, 1]
    )
    const xTitle = useTransform(
        yProgress,
        [0, 0.3, 0.6],
        [-64, -64, 0]
    )

    const opacityBody = useTransform(
        yProgress,
        [0, 0.3, 0.6],
        [0, 0, 1]
    )

    React.useEffect(() => {
        setInView(isInView)
    }, [isInView, setInView])

    return (
        <div ref={ ref } id={ id } className="relative min-h-screen bg-darkGreen-100 lg:sticky lg:top-0">
            <RenderCmsImage src={ image.large.url } fill alt="" className="absolute inset-0 z-[1] object-cover opacity-90" sizes="100vw" />

            <div className="story-gradient-left absolute inset-y-0 left-0 z-[1] w-1/5" />
            <div className="story-gradient-right absolute inset-y-0 right-0 z-[1] w-4/5" />

            <div className="container relative z-[2] grid gap-8 pb-6 pt-12 text-white lg:grid-cols-12 lg:gap-8 xl:gap-12">
                {/* Header */}
                <motion.div
                    className="col-span-full flex flex-col items-center gap-8 md:flex-row"
                    style={{ opacity: opacityTitle, x: xTitle }}
                >
                    <div className="inline-flex size-[120px] xl:size-[140px]">
                        <StoryCompass index={ index } />
                    </div>
                    <h2 className="huge">{title}</h2>
                </motion.div>

                {/* Body */}
                <motion.div
                    className="flex flex-col gap-8 lg:col-start-7 lg:col-end-13 xl:col-start-8"
                    style={{ opacity: opacityBody}}
                >
                    <div>
                        {title_te_reo && (<p className="h2-alt mb-2 font-bold">{title_te_reo}</p>)}
                        <div className={ classNames("richText", styles.storyBody) } dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }} />
                    </div>

                    {/* Indicators */}
                    {indicators.length > 0 && (
                        <div>
                            <h3 className="body-1 mb-4 font-bold">{`${ location }'s ${ title.toLowerCase() } data`}</h3>

                            <div className="flex flex-row gap-2">
                                {indicators.map((indicator) => (
                                    <div key={ indicator.slug } className="grow rounded-lg bg-darkGreen-0.8 backdrop-blur-[2px]">
                                        <Status
                                            isCard
                                            isSmall
                                            label={ indicator.label }
                                            status={ indicator.status }
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Button */}
                    <Button
                        onClick={ `${ urlToDataTool }?group=${ title.toLowerCase() }` }
                        isBold
                        withBorder
                        className="self-start"
                    >
                        Explore {title} data
                    </Button>
                </motion.div>
            </div>
        </div>
    )
}
