import { HealthStatus } from '@/types'
import classNames from 'classnames'
import React from 'react'
import ChevronIcon from '@/components/UI/Accordion/chevron.svg'

interface StatusProps {
    label: string
    status: HealthStatus
    isCard?: boolean
    isSmall?: boolean
    isHover?: boolean
    withChevron?: boolean
}

export const Status = ({ label, status, isCard, isSmall, isHover, withChevron }: StatusProps) => {
    const isHealthy = status === 'Healthy'
    const isUnhealthy = status === 'Unhealthy'
    const isVeryUnhealthy = status === 'Very Unhealthy'

    return (
        <div className={ classNames("flex flex-col gap-[6px]", isHover && "bg-white/10", isCard && "px-3 pt-2.5 pb-3 border border-darkGreen-70 rounded-lg") }>
            <div className={ classNames("flex flex-row gap-1 justify-between", isSmall && "flex-col") }>
                <p className={ classNames(
                    "text-darkGreen-10 flex items-center",
                    !isCard && !isSmall && "caption",
                    isSmall && "body-2",
                    isCard && "font-bold body-3"
                ) }>
                    {label}
                    {withChevron && <ChevronIcon className="inline size-[10px] rotate-[270deg]" />}
                </p>

                <p className={ classNames(
                    isHealthy && 'text-healthy',
                    isUnhealthy && 'text-unhealthy',
                    isVeryUnhealthy && 'text-veryUnhealthy',
                    !isCard && !isSmall && "caption",
                    isSmall && "body-3",
                    isCard && "body-3"
                ) }>{status}</p>
            </div>

            <div className="flex flex-row gap-1">
                <div className={ classNames('h-[5px] w-full rounded-[20px]', isHealthy ? "bg-healthy" : withChevron ? "bg-darkGreen-95" : "bg-darkGreen-80", !isHealthy && isHover && "bg-white/10" ) } />
                <div className={ classNames('h-[5px] w-full rounded-[20px]', isUnhealthy ? "bg-unhealthy" : withChevron ? "bg-darkGreen-95" : "bg-darkGreen-80", !isUnhealthy && isHover && "bg-white/10" ) } />
                <div className={ classNames('h-[5px] w-full rounded-[20px]', isVeryUnhealthy ? "bg-veryUnhealthy" : withChevron ? "bg-darkGreen-95" : "bg-darkGreen-80", !isVeryUnhealthy && isHover && "bg-white/10" ) } />
            </div>
        </div>
    )
}
