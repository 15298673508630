/**
 * compassOrder matches each ring, this means the label is CMS driven
 */
export function DataCompassIcon({ compassOrder }: { compassOrder: Array<{ id: string; label: string }> }) {
    return (
        <div className="max-h-[185px] w-1/2 max-w-[190px] md:w-3/5">
            <svg className="size-full" viewBox="0 0 145 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M144.051 70C144.051 108.66 112.699 140 74.0253 140C35.3514 140 4 108.66 4 70C4 31.3401 35.3514 0 74.0253 0C112.699 0 144.051 31.3401 144.051 70ZM14.9806 70C14.9806 102.598 41.4158 129.023 74.0253 129.023C106.635 129.023 133.07 102.598 133.07 70C133.07 37.4023 106.635 10.9766 74.0253 10.9766C41.4158 10.9766 14.9806 37.4023 14.9806 70Z" fill="#35CBC3"/>
                <path d="M131.32 70.0012C131.32 101.632 105.668 127.274 74.0262 127.274C42.3839 127.274 16.7328 101.632 16.7328 70.0012C16.7328 38.3704 42.3839 12.7285 74.0262 12.7285C105.668 12.7285 131.32 38.3704 131.32 70.0012ZM26.9141 70.0012C26.9141 96.0112 48.0069 117.096 74.0262 117.096C100.046 117.096 121.138 96.0112 121.138 70.0012C121.138 43.9913 100.046 22.9061 74.0262 22.9061C48.0069 22.9061 26.9141 43.9913 26.9141 70.0012Z" fill="#39C38D"/>
                <path d="M119.494 69.9982C119.494 95.1021 99.1364 115.453 74.0234 115.453C48.9105 115.453 28.5525 95.1021 28.5525 69.9982C28.5525 44.8944 48.9105 24.5437 74.0234 24.5437C99.1364 24.5437 119.494 44.8944 119.494 69.9982ZM38.5177 69.9982C38.5177 89.6004 54.4142 105.491 74.0234 105.491C93.6327 105.491 109.529 89.6004 109.529 69.9982C109.529 50.3961 93.6327 34.5053 74.0234 34.5053C54.4142 34.5053 38.5177 50.3961 38.5177 69.9982Z" fill="#62C461"/>
                <path d="M107.673 69.9996C107.673 88.5765 92.6084 103.636 74.0248 103.636C55.4413 103.636 40.3763 88.5765 40.3763 69.9996C40.3763 51.4228 55.4413 36.3633 74.0248 36.3633C92.6084 36.3633 107.673 51.4228 107.673 69.9996ZM50.4652 69.9996C50.4652 83.0066 61.0132 93.5508 74.0248 93.5508C87.0365 93.5508 97.5845 83.0066 97.5845 69.9996C97.5845 56.9927 87.0365 46.4485 74.0248 46.4485C61.0132 46.4485 50.4652 56.9927 50.4652 69.9996Z" fill="#89D263"/>
                <path d="M95.8524 70.0013C95.8524 82.0511 86.0806 91.8195 74.0264 91.8195C61.9722 91.8195 52.2003 82.0511 52.2003 70.0013C52.2003 57.9514 61.9722 48.1831 74.0264 48.1831C86.0806 48.1831 95.8524 57.9514 95.8524 70.0013ZM61.9391 70.0013C61.9391 76.6745 67.3507 82.0842 74.0264 82.0842C80.702 82.0842 86.1137 76.6745 86.1137 70.0013C86.1137 63.3281 80.702 57.9183 74.0264 57.9183C67.3507 57.9183 61.9391 63.3281 61.9391 70.0013Z" fill="#BBD45A"/>
                <rect width="71" height="70" transform="matrix(-1 0 0 1 73 0)" fill="url(#paint0_radial_1_4433)"/>
                
                {compassOrder[0] && (
                    <>
                        <path d="M0 2C0 0.895431 0.895431 0 2 0H73C74.1046 0 75 0.895431 75 2V9C75 10.1046 74.1046 11 73 11H2C0.895429 11 0 10.1046 0 9V2Z" fill="#35CBC3"/>
                        <text className="fill-darkGreen-100 font-bold uppercase" fontSize="8px" transform="translate(2 8.5)">{compassOrder[0].label}</text>
                    </>
                )}
                
                {compassOrder[1] && (
                    <>
                        <path d="M0 15C0 13.8954 0.895431 13 2 13H73C74.1046 13 75 13.8954 75 15V21C75 22.1046 74.1046 23 73 23H2C0.895429 23 0 22.1046 0 21V15Z" fill="#39C38D"/>
                        <text className="fill-darkGreen-100 font-bold uppercase" fontSize="8px" transform="translate(2 21)">{compassOrder[1].label}</text>
                    </>
                )}
                
                {compassOrder[2] && (
                    <>
                        <path d="M0 26.5C0 25.3954 0.895431 24.5 2 24.5H73C74.1046 24.5 75 25.3954 75 26.5V32.5C75 33.6046 74.1046 34.5 73 34.5H2C0.895429 34.5 0 33.6046 0 32.5V26.5Z" fill="#62C461"/>
                        <text className="fill-darkGreen-100 font-bold uppercase" fontSize="8px" transform="translate(2 32.75)">{compassOrder[2].label}</text>
                    </>
                )}
                
                {compassOrder[3] && (
                    <>
                        <path d="M0 38.3999C0 37.2953 0.895431 36.3999 2 36.3999H73C74.1046 36.3999 75 37.2953 75 38.3999V44.3999C75 45.5045 74.1046 46.3999 73 46.3999H2C0.895429 46.3999 0 45.5045 0 44.3999V38.3999Z" fill="#89D263"/>
                        <text className="fill-darkGreen-100 font-bold uppercase" fontSize="8px" transform="translate(2 44.75)">{compassOrder[3].label}</text>
                    </>
                )}
                
                {compassOrder[4] && (
                    <>
                        <path d="M0 50.1999C0 49.0954 0.895431 48.2 2 48.2H73C74.1046 48.2 75 49.0954 75 50.2V55.9C75 57.0045 74.1046 57.9 73 57.9H2C0.895429 57.9 0 57.0045 0 55.9V50.1999Z" fill="#BBD45A"/>
                        <text className="fill-darkGreen-100 font-bold uppercase" fontSize="8px" transform="translate(2 56.2)">{compassOrder[4].label}</text>
                    </>
                )}
                
                <defs>
                    <radialGradient id="paint0_radial_1_4433" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90) scale(70 71)">
                        <stop stopColor="#194141"/>
                        <stop offset="0.7" stopColor="#194141" stopOpacity="0.51"/>
                        <stop offset="1" stopColor="#194141" stopOpacity="0"/>
                    </radialGradient>
                </defs>
            </svg>
        </div>
    )
}