'use client'

import { StoryPageData } from '@/types'
import classNames from 'classnames'
import React from 'react'

interface IndicatorsProps {
    data: StoryPageData[]
    activeIndex: boolean[]
}

export const Indicators = ({data = [], activeIndex}: IndicatorsProps) => {
    return (
        <ul className="absolute left-20 top-1/2 flex -translate-y-1/2 flex-col gap-3">
            {data.map((story, index) => {
                const lastIndex = activeIndex.findLastIndex((val) => val) || 0
                const isActive = index === lastIndex

                return (
                    <li
                        key={ story.id }
                        className={ classNames(
                            "rounded-full w-[10px] h-[10px] border-2 border-white transition-colors",
                            isActive ? "bg-white" : "bg-transparent"
                        ) }
                    />
                )
            })}
        </ul>
    )
}
