import React from "react"

const QUERY = '(prefers-reduced-motion: no-preference)'
const isRenderingOnServer = typeof window === 'undefined'

const getInitialState = () => {
    // For our initial server render, we won't know if the user
    // prefers reduced motion, but it doesn't matter. This value
    // will be overwritten on the client, before any animations
    // occur.
    return isRenderingOnServer ? false : !window.matchMedia(QUERY).matches
}

/**
 * Returns if the user prefers reduced motion so we can disable animations or pause videos.
 * 
 * Based on https://www.joshwcomeau.com/snippets/react-hooks/use-prefers-reduced-motion/
 */
export function usePrefersReducedMotion(): boolean {
    const [prefersReducedMotion, setPrefersReducedMotion] = React.useState(
        getInitialState
    )
    React.useEffect(() => {
        const mediaQueryList = window.matchMedia(QUERY)
        const listener = (event) => {
            setPrefersReducedMotion(!event.matches)
        }
        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener('change', listener)
        } else {
            mediaQueryList.addListener(listener)
        }
        return () => {
            if (mediaQueryList.removeEventListener) {
                mediaQueryList.removeEventListener('change', listener)
            } else {
                mediaQueryList.removeListener(listener)
            }    }
    }, [])

    return prefersReducedMotion
}