'use client'

const NEXT_PUBLIC_BASE_URL = process.env.NEXT_PUBLIC_BASE_URL

import Image, { ImageLoaderProps, ImageProps } from "next/image"

/**
 * This handles images from the Wagtail CMS '/media/images/'
 * This would need to be altered if we decided to load images from another host e.g. imgix
 */
export function cmsImageLoader({ src }: ImageLoaderProps) {
    // Use relative URL on the client or the NEXT_PUBLIC_BASE_URL on the server, this prevents mismatches between client and server
    if (typeof window !== "undefined") {
        return src
    }

    return `${ NEXT_PUBLIC_BASE_URL }/${ src }`
}

/**
 * Will handle rendering an image from the CMS
 */
export const RenderCmsImage = (props: ImageProps) => {
    return <Image { ...props } src={ props.src } alt={ props.alt || "" } loader={ cmsImageLoader } />
}
