import classNames from 'classnames'
import DOMPurify from 'isomorphic-dompurify'

interface RichTextBlockProps {
    value: string
    isNested?: boolean
}

export function RichTextBlock({ value, isNested }: RichTextBlockProps) {
    return (
        <div className={ classNames("richText", !isNested && "pt-20") } dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} />
    )
}
