import cn from 'classnames'
import DOMPurify from 'isomorphic-dompurify'
import { CallToAction, ImageType } from '@/types'
import { Button } from '@/components/UI/Button/Button'
import { RenderCmsImage } from '@/components/UI/RenderCmsImage/RenderCmsImage'

export interface ImageAndTextBlockProps {
    title?: string
    links: CallToAction[]
    image: ImageType
    imageDirection: 'left' | 'right'
    body?: string
}

export function ImageAndTextBlock({ value }: { value: ImageAndTextBlockProps }) {
    const {
        title,
        image,
        imageDirection = 'left',
        body = '',
        links = [],
    } = value

    return (
        <div className="grid grid-cols-1 items-center gap-12 py-10 lg:min-h-[70vh] lg:grid-cols-2 lg:gap-20 lg:py-20">
            <div className={ cn('relative h-[400px] lg:h-full', imageDirection === 'right' && 'lg:order-2') }>
                <RenderCmsImage
                    src={ `${ image.large.src }` }
                    alt={ image.alt }
                    // height={ image.large.height }
                    // width={ image.large.width }
                    style={{ objectFit: 'cover' }}
                    fill
                    sizes="(max-width: 1024px) 80vw, 50vw"
                />
            </div>

            <div className={ cn(
                'flex flex-col gap-6 lg:gap-8', imageDirection === 'right' ? 'lg:order-1' : 'order-last'
            ) }
            >
                <h2>{title}</h2>

                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }} />

                {links.map((link) => (
                    <Button className="self-start" key={ link.url } onClick={ link.url } openInNewTab={ link.openInNewTab } ariaLabel={ link.ariaLabel } withBorder>
                        {link.label}
                    </Button>
                ))}
            </div>
        </div>
    )
}
