const BILLION = 1000000000
const MILLION = 1000000

function numberWithCommas(x: string): string {
    return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function displayMetric(metric: number): string {
    // Strip out any trailing zeros
    const num = parseFloat(metric.toString())

    if (num >= BILLION) {
        return (num / BILLION).toFixed(1) + 'B'
    }
    
    if (num >= MILLION) {
        // Numbers over a million are suffixed with M
        return (num / MILLION).toFixed(1) + 'M'
    }

    // Handles 999,999 to 10,000
    if (num >= 10000) {
        // Numbers over a million are suffixed with M
        return numberWithCommas(num.toFixed(0))
    }

    if (num >= 1000) {
        // Numbers over a million are suffixed with M
        return numberWithCommas(num.toFixed(1).replace(/\.0+$/, ''))
    }

    // 100.55 -> 100.55
    // 100.00 -> 100
    // 10.5 -> 10.5
    // 10.12345 -> 10.12
    if (num >= 10) {
        // Numbers over a million are suffixed with M
        return num.toFixed(2).replace(/\.?0+$/, '')
    }
    
    // Handles anything less than 10
    return num.toFixed(4).replace(/\.?0+$/, '')

}
