import { BlockType } from '@/types'
import { Debug } from '../UI/Debug/Debug'

const isProduction = process.env.NODE_ENV === 'production'

interface TempBlockProps extends BlockType {
    title?: string
}

/**
 * TempBlock is a placeholder for a block that has not been implemented yet
 */
export default function TempBlock(props: TempBlockProps) {
    if (isProduction) {
        return null
    }
    
    const { title, type } = props
    const name = title || type

    return (
        <div className="container h-full pt-20">
            <p>TempBlock. RenderComponents cannot render <b>{name}</b>.</p>

            <p>You probably need to add <b>{name}</b> to the <b>COMPONENTS_LIST</b> in <b>RenderComponents.tsx</b> with the appropriate React component to render out the content.</p>

            <Debug>
                <code>
                    {JSON.stringify(props)}
                </code>
            </Debug>
        </div>
    )
}
