import { Button } from '@/components/UI/Button/Button'
import { CallToAction } from '@/types'
import React from 'react'

interface LinkBlockProps {
    id: string
    value: CallToAction
}

/**
 * Used with `link_blocks.py` from Wagtail
 */
export const LinkBlock = ({ value }: LinkBlockProps) => {
    const { url, label, ariaLabel, openInNewTab } = value
    
    return (
        <Button className="mt-8" onClick={ url } openInNewTab={ openInNewTab } ariaLabel={ ariaLabel } withBorder>
            {label}
        </Button>
    )
}
