import type { LivingCitiesPageProps } from '@/components/Pages/LivingCitiesPage/LivingCitiesPage'

export const mockChristchurchLivingCitiesPage: Omit<LivingCitiesPageProps, 'data'> = {
    id: 1,
    title: 'Placeholder Page',
    living_cities_api: '/api/v2/living_cities/',
    location_title: 'Christchurch',
    location_title_te_reo: 'Ōtautahi',
    heading_one: 'Explore Christchurch’s living data compass',
    body: 'Our Ngā Tohu Ora living cities data sensor takes the pulse of our living changing city. Sensing the city in this way draws attention to the liveliness of our cities and how that vitality can be enhanced. ',
    meta: {
        slug: '/christchurch/living-cities/',
        type: 'app.LCSDPage',
        locale: '',
        html_url: '',
        detail_url: '',
    },
    // CMS editable fields, category gets 
    categories: [
        {
            category: 'ecology',
            title: 'Ecology',
            altTitle: 'Wai Ora & Whenua Ora',
            subHeading: 'Regenerating urban ecosystems',
        },
        {
            category: 'energy',
            title: 'Energy',
            altTitle: 'Hihiri-Oranga',
            subHeading: 'Short subheading for',
        },
        {
            category: 'economy',
            title: 'Economy',
            altTitle: 'Ōhanga-Oranga',
            subHeading: 'Short subheading for',
        },
        {
            category: 'urban',
            title: 'Urban',
            altTitle: 'Te reo Māori title',
            subHeading: 'Short subheading for',
        },
        {
            category: 'architecture',
            title: 'Architecture',
            altTitle: 'Te reo Māori title',
            subHeading: 'Short subheading for',
        },
    ],
}
