
/**
 * Gets the graph bounds area as it changes depending on what panel is open "Overview" or "Indicator"
 */
export function getGraphDrawArea() {
    const graphScope = document.getElementById("graphDrawArea")
    const { width, height, x, y } = graphScope.getBoundingClientRect()

    return {
        width,
        height,
        x,
        y,
    }
}

export function getScreenSize() {
    const width = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

    return {
        width,
        height,
    }
}

interface GetScaleToFitElementProps {
    previousK: number
    containerWidth: number
    containerHeight: number
    elementWidth: number
    elementHeight: number
    shouldLog?: boolean
}

export const MIN_ZOOM = 0.85 // Effects how far you can zoom out (scales the element down)
export const MAX_ZOOM = 2.5 // Effects how far you can zoom in (scales the element up)

/**
 * Takes the current container and element dimensions (compass view area) and returns the scale factor to fit the element inside the container
 */
export function getScaleToFitElement({
    previousK,
    containerWidth,
    containerHeight,
    elementWidth,
    elementHeight,
    // shouldLog,
}: GetScaleToFitElementProps): number {
    const reverseScaleK = 1 / previousK

    const horizontalK = (containerWidth / (elementWidth * reverseScaleK))
    const verticalK = (containerHeight / (elementHeight * reverseScaleK))

    const zoomArea = Math.min(horizontalK, verticalK) * 0.8
    let k = zoomArea

    // if (shouldLog) {
    //     console.log(`reverseScaleK: 1 / ${ previousK } = ${ reverseScaleK }`)
    //     console.log(`horizontalK: ${ containerWidth } / (${ elementWidth } * ${ reverseScaleK }) = ${ horizontalK }`)
    //     console.log(`verticalK: ${ containerHeight } / (${ elementHeight } * ${ reverseScaleK }) = ${ verticalK }`)
    //     console.log(`zoomArea: Math.min(${ horizontalK }, ${ verticalK }) * 0.8 = ${ zoomArea }`)
    //     console.log(`k: ${ k }`)
    // }

    // If it's roughly the same zoom, don't change the current zoom level
    // if (k.toFixed(3) === previousK.toFixed(3)) {
    //     console.log("=( same zoom: k = 1 )=")
    //     return 1
    // }

    if (k < MIN_ZOOM) {
        return MIN_ZOOM
    }

    if (k > MAX_ZOOM) {
        return MAX_ZOOM
    }

    return k
}   
