import DOMPurify from 'isomorphic-dompurify'
import React from 'react'
import { Accordion } from '@/components/UI/Accordion/Accordion'

interface AccordionItemBlock {
  title: string
  subText?: string
  body: string
}

interface AccordionBlockProps {
  value: {
    items?: AccordionItemBlock[]
  }
}

export const AccordionBlock = ({
    value,
}: AccordionBlockProps) => {
    const { items = [] } = value

    return (
        <div className="mt-8 flex flex-col lg:mt-12">
            {items.map(({ title, subText, body }) => (
                <Accordion title={ title } subText={ subText } key={ title }>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }} />
                </Accordion>
            ))}
        </div>
    )
}
