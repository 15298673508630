import React from 'react'
import classNames from 'classnames'

interface StoryCompassProps {
    isLivingCities?: boolean;
    index?: number;
}

export const StoryCompass = ({
    isLivingCities,
    index,
}: StoryCompassProps) => {
    const isEcology = index === 0
    const isEnergy = index === 1
    const isEconomy = index === 2
    const isUrban = index === 3
    const isArchitecture = index === 4

    const strokeEcology = isLivingCities ? 'stroke-livingCitiesEcology' : 'stroke-ecology'
    const strokeEnergy = isLivingCities ? 'stroke-livingCitiesEnergy' : 'stroke-energy'
    const strokeEconomy = isLivingCities ? 'stroke-livingCitiesEconomy' : 'stroke-economy'
    const strokeUrban = isLivingCities ? 'stroke-livingCitiesUrban' : 'stroke-urban'
    const strokeArchitecture = isLivingCities ? 'stroke-livingCitiesArchitecture' : 'stroke-architecture'
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" fill="none">
            <circle r="66" cx="70" cy="70" strokeWidth="8" className={ classNames(isEcology ? strokeEcology : 'stroke-white/30') } />
            <circle r="55" cx="70" cy="70" strokeWidth="8" className={ classNames(isEnergy ? strokeEnergy : 'stroke-white/30') } />
            <circle r="44" cx="70" cy="70" strokeWidth="8" className={ classNames(isEconomy ? strokeEconomy : 'stroke-white/30') } />
            <circle r="33" cx="70" cy="70" strokeWidth="8" className={ classNames(isUrban ? strokeUrban : 'stroke-white/30') } />
            <circle r="22" cx="70" cy="70" strokeWidth="8" className={ classNames(isArchitecture ? strokeArchitecture : 'stroke-white/30') } />
        </svg>
    )
}
