import { DataCompassPageProps } from '@/components/Pages/DataCompassPage/DataCompassPage'

export const mockChristchurchDataPage: Omit<DataCompassPageProps, 'compassGraphData'> = {
    id: 1,
    title: 'Placeholder data compass page',
    meta: {
        slug: '/data/',
        type: 'app.DataToolPage',
        locale: '',
        html_url: '',
        detail_url: '',
    },
    location_title: 'Christchurch',
    location_title_te_reo: 'Ōtautahi',
}
