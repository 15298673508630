'use client'

import { CompassGraphDataV2, Indicator } from '@/types'
import React from 'react'
import { pie, arc, select, zoom, D3ZoomEvent, zoomIdentity, ZoomTransform, zoomTransform } from 'd3'
import classNames from 'classnames'
import { Tooltip } from '../Tooltip/Tooltip'
import styles from './CompassGraph.module.scss'
import { Button } from '@/components/UI/Button/Button'
import {MAX_ZOOM, MIN_ZOOM, getScaleToFitElement} from './compassGraphHelpers'
import { useViewportSize } from '@mantine/hooks'
import PlusIcon from './plus.svg'
import MinusIcon from './minus.svg'

const GRAPH_CONSTANTS = {
    size: 900,
    radius: 450, // size / 2

    padRadius: 14, // Padding between rings

    ringOffset: 56, // Offset/space between compass rings

    widthOfPill: 48,

    pillCenterSize: 4,

    pillCornerRadius: 10, // Rounded corners of pills
} as const

function calculateArcs(indicators: Indicator[], index: number) {
    // Use value 1 because all indicators are equal in size
    const dataWithPie = pie<any, Indicator>().value(() => 1)(indicators)

    const arcPathGenerator = arc().padRadius(GRAPH_CONSTANTS.padRadius).cornerRadius(GRAPH_CONSTANTS.pillCornerRadius)
    
    // Used to show white border around foreground when active
    const dataPointsBackground = dataWithPie.map((p) => {
        const arc = arcPathGenerator({
            outerRadius: ((GRAPH_CONSTANTS.radius) - (GRAPH_CONSTANTS.ringOffset) * index) - GRAPH_CONSTANTS.pillCenterSize,
            innerRadius: ((GRAPH_CONSTANTS.radius) - (GRAPH_CONSTANTS.ringOffset) * index - GRAPH_CONSTANTS.widthOfPill) + GRAPH_CONSTANTS.pillCenterSize,
            startAngle: p.startAngle,
            padAngle: 0.7,
            endAngle: p.endAngle,
        })
    
        return {
            ...p,
            arc,
        }
    })

    return { dataPointsBackground }
}

/**
 * Finds and zooms in on the given indicator
 */
const zoomInOnIndicator = (activeIndicatorId: string) => {
    // Root SVG - contains the zoom handler and the viewport for the compass graph
    const rootSvg = select<SVGElement, unknown>('#rootSvg')
    const rootSvgRect = rootSvg.node().getBoundingClientRect()

    // Group transform - contains the compass graph
    const groupTransform = select<SVGGElement, unknown>('#groupTransform')

    // Find active indicator element
    // d3 "select" uses the querySelector method which uses CSS3 selectors for querying the DOM and CSS3 doesn't support ID selectors that start with a digit
    if (activeIndicatorId.match(/^\d/)) {
        return
    }

    const indicator = select<SVGPathElement, unknown>(`#${ activeIndicatorId }`)

    if (!indicator) {
        return
    }

    const indicatorRect = indicator.node().getBoundingClientRect()

    const isEnabled = groupTransform.attr("data-enabled")
    if (!isEnabled) {
        return
    }

    // Get the previous transform, indicator won't have one but it will find the group element
    const previousTransform = zoomTransform(groupTransform.node())

    const k = getScaleToFitElement({
        previousK: previousTransform.k,
        containerWidth: rootSvgRect.width,
        containerHeight: rootSvgRect.height,
        elementWidth: indicatorRect.width,
        elementHeight: indicatorRect.height,
    })

    // getBBox returns values respecting the current SVG space 
    const indicatorBBox = indicator.node().getBBox()

    // Find the centre of the indicator element
    const x = -indicatorBBox.x - (indicatorBBox.width / 2)
    const y = -indicatorBBox.y - (indicatorBBox.height / 2)

    // Use zoomIdentity to apply the scale and transform which is zeroed x=0, y=0, k=1
    const newTransform = zoomIdentity.scale(k).translate(x, y)

    // Update rootSvg and groupTransform with the new transform
    rootSvg.call(zoom().transform, newTransform)
    groupTransform.transition().duration(1000).attr(
        "transform",
        newTransform.toString()
    )

    // So we can update the debugValues
    return { x, y, k }
}

interface CompassGraphProps {
    data: CompassGraphDataV2
    dataCompassPageUrl: string
    activeCompassGroupId?: string
    activeIndicatorId?: string
    indicatorHoverId?: string
    setSelectedIndicator?: (indicator: { compassGroupId?: string, indicatorId?: string }) => void
    isReadOnly?: boolean
}

export const CompassGraph = ({data, dataCompassPageUrl, activeCompassGroupId, activeIndicatorId, indicatorHoverId, setSelectedIndicator, isReadOnly}: CompassGraphProps) => {
    const [tooltipText, setTooltipText] = React.useState<string | null>(null)
    const isIndicatorSelected = !!(activeCompassGroupId && activeIndicatorId)
    const initialTransformRef = React.useRef<ZoomTransform>(null)
    const initialGroupSize = React.useRef<{ width: number, height: number, k: number }>(null)
    const { width: viewportWidth } = useViewportSize()
    const isCompassGraphEnabled = !(viewportWidth < 1024)

    React.useEffect(() => {
        const groupTransform = select<SVGGElement, unknown>('#groupTransform')
        const isEnabled = groupTransform.attr("data-enabled")

        if (isReadOnly) {
            groupTransform.attr("data-enabled", null)
            return
        }

        if (isCompassGraphEnabled && isEnabled) {
            return
        }

        if (!isCompassGraphEnabled && !isEnabled) {
            return
        }

        if (isCompassGraphEnabled && !isEnabled) {
            groupTransform.attr("data-enabled", "1")
        }

        if (!isCompassGraphEnabled && isEnabled) {
            groupTransform.attr("data-enabled", null)
        }
    }, [isCompassGraphEnabled, isReadOnly])

    React.useEffect(() => {
        const handleZoom = (evt: D3ZoomEvent<SVGSVGElement, unknown>) => {
            const groupTransform = select<SVGGElement, unknown>('#groupTransform')

            const isReady = groupTransform.attr("data-ready")
            const isEnabled = groupTransform.attr("data-enabled")

            if (!isReady || !isEnabled) {
                return
            }

            groupTransform.transition().duration(0).attr("transform", evt.transform.toString())
        }
        
        const rootSvg = select<SVGElement, unknown>('#rootSvg')
        const groupTransform = select<SVGGElement, unknown>('#groupTransform')
        // const rootSvgRect = rootSvg.node().getBoundingClientRect()

        const x = 0
        const y = 0
        const k = 1

        const initialTransform = zoomIdentity.translate(x, y).scale(k)
        initialTransformRef.current = initialTransform

        // We should probably set constraints?
        const zoomBehavior = zoom<SVGSVGElement, unknown>()
            .scaleExtent([MIN_ZOOM, MAX_ZOOM]) // Set min and max zoom levels
            // .translateExtent([
            //     [-halfWidth, -halfHeight],
            //     [halfWidth * 2, halfHeight * 2],
            // ]) // Set min and max translate values (boundaries)
            .on('zoom', handleZoom)

        // Set opacity so D3 knows about it and can transition from it
        groupTransform.attr("opacity", 0)

        // Setup zoom handler, but not for mouse wheel
        rootSvg.call(zoomBehavior).on("wheel.zoom", null)
        // Setup initial zoom transform
        // @ts-expect-error this is how it's done in d3 docs
        rootSvg.call(zoomBehavior.transform, initialTransform)

        setTimeout(() => {
            // groupTransform.transition().duration(0).attr("transform", initialTransform.toString())
            groupTransform.transition().duration(100).attr("opacity", 1).end().then(() => {
                const groupTransform = select<SVGGElement, unknown>('#groupTransform')
                // Enable zoom handling
                groupTransform.attr("data-ready", "1")

                // Set actual size of the group element
                const {width, height} = groupTransform.node().getBoundingClientRect()
                const k = width / 900
                initialGroupSize.current = {width, height, k}

                if (!activeCompassGroupId || !activeIndicatorId) {
                    return
                }
            })
        }, 1)
    }, [])

    const onZoom = (type: 'in' | 'out') => {
        const rootSvg = select<SVGElement, unknown>('#rootSvg')
        const groupTransform = select<SVGGElement, unknown>('#groupTransform')

        const previousTransform = zoomTransform(groupTransform.node())

        const k = type === 'in' ? Math.min(MAX_ZOOM, previousTransform.k + .5) : Math.max(MIN_ZOOM, previousTransform.k - .5)

        const newTransform = zoomIdentity.translate(previousTransform.x, previousTransform.y).scale(k)

        rootSvg.call(zoom().transform, newTransform)
        groupTransform.transition().duration(500).attr("transform", newTransform.toString())
    }
    
    const onResetGraph = () => {
        const rootSvg = select<SVGElement, unknown>('#rootSvg')
        const groupTransform = select<SVGGElement, unknown>('#groupTransform')

        const initialTransform = initialTransformRef.current

        rootSvg.call(zoom().transform, initialTransform)
        groupTransform.transition().duration(500).attr("transform", initialTransform.toString())
    }

    React.useEffect(() => {
        if (!activeCompassGroupId || !activeIndicatorId) {
            return
        }
        
        const transform = zoomInOnIndicator(activeIndicatorId)

        if (!transform) {
            return
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCompassGroupId, activeIndicatorId])

    return (
        <>
            {tooltipText && (
                <Tooltip offset={{ x: 16, y: -24 }}>
                    <p className="relative rounded-lg bg-white px-2 pb-1.5 pt-1 text-darkGreen-90 shadow">
                        {tooltipText}

                        {/* Arrow - left */}
                        <span className="absolute right-full top-[13px] z-10 -mr-px size-0 border-y-4
                        border-r-[6px] border-y-transparent
                        border-r-white" />
                    </p>
                </Tooltip>
            )}

            <svg
                className={ classNames(styles.rootSvg, isReadOnly && "pointer-events-none") }
                id="rootSvg"
                viewBox={ `0 0 ${ GRAPH_CONSTANTS.size } ${ GRAPH_CONSTANTS.size }` }
                preserveAspectRatio="xMidYMid meet"
                onClick={ (evt) => {
                    if (isReadOnly) {
                        return
                    }
                    
                    const target: HTMLElement = evt.target as HTMLElement

                    const dataCompass = target?.getAttribute('data-compass')
                    const dataIndicator = target?.getAttribute('data-indicator')

                    if (!dataCompass || !dataIndicator) {
                        return
                    }

                    // Stop the click event hitting the close onClick area behind the graph
                    evt.stopPropagation()
                    window.history.pushState({}, '', `${ dataCompassPageUrl }${ dataCompass }/${ dataIndicator }/`)
                    setSelectedIndicator({ compassGroupId: dataCompass, indicatorId: dataIndicator })
                    setTooltipText(null)
                } }
            >
                <g id="groupTransform" opacity="0">
                    <g transform={ `translate(${ GRAPH_CONSTANTS.radius }, ${ GRAPH_CONSTANTS.radius })` }>
                        {/* Outer loop, creates a full ring */}
                        {data.order.map(({ id }, compassIndex) => {
                            const compassGroupIndicators = data[id]
                            const {dataPointsBackground} = calculateArcs(compassGroupIndicators, compassIndex)

                            // Inner loop, creates a single indicator arc
                            return dataPointsBackground.map((dataPointWithArc) => {
                                const { data: indicator, arc } = dataPointWithArc
                                const {slug: id, status, compassId} = indicator

                                const isActive = activeCompassGroupId === compassId && activeIndicatorId === id
                                const isHoveredFromOverviewPanel = indicatorHoverId === id

                                return (
                                    <path
                                        key={ id }
                                        className={ classNames(
                                            styles.indicator,
                                            status === 'Healthy' && styles.isHealthy,
                                            status === 'Unhealthy' && styles.isUnhealthy,
                                            status === 'Very Unhealthy' && styles.isVeryUnhealthy,
                                            isIndicatorSelected && styles.isIndicatorSelected,
                                            isActive && styles.isActive,
                                            isHoveredFromOverviewPanel && styles.isHoveredFromOverviewPanel,
                                            indicatorHoverId && !isHoveredFromOverviewPanel && styles.isHoveredFromOverviewPanelButNotActive
                                        ) }
                                        d={ arc }
                                        id={ id }
                                        data-indicator={ id }
                                        data-compass={ compassId }
                                        onMouseEnter={ () => {
                                            setTooltipText(indicator.label)
                                        } }
                                        onMouseLeave={ () => {
                                            setTooltipText(null)
                                        } }
                                    />
                                )
                            })
                        })}
                    </g>
                </g>
            </svg>

            {isCompassGraphEnabled && !isReadOnly && (
                <div className="bottom-0 left-0 hidden flex-col gap-2 p-4 lg:absolute lg:flex">
                    <div className="flex flex-row">
                        <button aria-label="Zoom in" className="rounded-l-lg border border-white bg-darkGreen-100/50 stroke-white p-2 text-white hover:z-10 hover:border-ecology hover:stroke-ecology" onClick={ (evt) => {
                            evt.preventDefault()
                            evt.stopPropagation()
                            onZoom('in')
                        } }>
                            <PlusIcon className="size-6" />
                        </button>

                        <button aria-label="Zoom out" className="-ml-px rounded-r-lg border border-white bg-darkGreen-100/50 stroke-white p-2 text-white hover:z-10 hover:border-ecology hover:stroke-ecology" onClick={ (evt) => {
                            evt.preventDefault()
                            evt.stopPropagation()
                            onZoom('out')
                        } }>
                            <MinusIcon className="size-6" />
                        </button>
                    </div>

                    <Button withBorder isBold className="flex-1 justify-center bg-darkGreen-100/50" onClick={ (evt) => {
                        evt.preventDefault()
                        onResetGraph()
                    } }>
                        Reset
                    </Button>
                </div>
            )}
        </>
    )
}
