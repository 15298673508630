'use client'

import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { usePathname } from 'next/navigation'
import Script from 'next/script'

/**
 * Renders the Wagtail user bar component only if the user has a Wagtail session
 */
export function WagtailUserBar() {
    const [wagtailUserBar, setWagtailUserBar] = useState()
    const pathname = usePathname()
    const url = `/api/v2/pages/with-path${ pathname }`

    useEffect(() => {
        let cookie = ''
        const cookieStore = Cookies

        const sessionId = cookieStore.get('sessionid')?.value

        if (!sessionId) {
            return
        }

        const csrfToken = cookieStore.get('csrftoken')?.value

        cookie = `csrftoken=${ csrfToken };sessionid=${ sessionId }`

        fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Cookie': cookie,
            },
        }).then((response) => {
            return response.json()
        }).then((data) => {
            if (data?.wagtail_userbar?.value?.html) {
                setWagtailUserBar(data.wagtail_userbar.value.html)
            }
        })
    }, [url])

    if (!wagtailUserBar) {
        return null
    }

    return (
        <>
            <div className="z-[100] text-base md:text-lg" suppressHydrationWarning={ true } dangerouslySetInnerHTML={{ __html: wagtailUserBar }} />
            <Script src="/static/wagtailadmin/js/vendor.js" />
            <Script src="/static/wagtailadmin/js/userbar.js" />
        </>
    )
}
