'use client'

import React from 'react'
import ArrowDownSVG from '@/assets/svg/arrow-down.svg'

interface ScrollToButtonProps {
    id: string
}

export const ScrollToButton = ({id}: ScrollToButtonProps) => {
    return (
        <button
            onClick={ () => {
                const ecologyEl = document.getElementById(id)
                if (ecologyEl) {
                    ecologyEl.scrollIntoView({ behavior: 'smooth' })
                }
            } }
            className="hover:text-ecology"
        >
            <ArrowDownSVG />
        </button>
    )
}
