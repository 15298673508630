'use client'

import classNames from 'classnames'
import React from 'react'
import { createPortal } from 'react-dom'
import { motion } from 'framer-motion'

interface TooltipProps {
    offset?: { x: number, y: number }
    children: React.ReactNode
}

export const Tooltip = ({ children, offset = { x: 0, y: 0} }: TooltipProps) => {
    const element = React.useRef<HTMLDivElement>(null)

    React.useEffect(() => {
        function handler(e: MouseEvent) {
            if (element.current) {
                const x = e.clientX + offset.x
                const y = e.clientY + offset.y

                element.current.style.transform = `translate(${ x }px, ${ y }px)`
            }
        }
        document.addEventListener('mousemove', handler)

        function hideOnScroll() {
            if (element.current) {
                element.current.style.opacity = '0'
            }
        }
        document.addEventListener('scroll', hideOnScroll)
        
        return () => {
            document.removeEventListener('mousemove', handler)
            document.removeEventListener('scroll', hideOnScroll)
        }
    }, [offset.x, offset.y])

    // Use a motion.div to prevent the tooltip from flickering as you mouse between indicators
    return createPortal(
        <motion.div initial={{opacity: 0}} animate={{opacity: 1, transition: { delay: 0.2 }}} className={ classNames("fixed pointer-events-none left-0 top-0 z-10", !children ? "invisible lg:invisible" : "invisible lg:visible") } ref={ element }>
            {children}
        </motion.div>
        , document.body)
}
