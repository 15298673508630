import React from 'react'

interface HeroBlockProps {
    title: string
}

export const HeroBlock = ({value}: { value: HeroBlockProps}) => {
    const { title } = value

    return (
        <section className="container">
            <h1>{title}</h1>
        </section>
    )
}
