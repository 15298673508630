import { CallToAction, ImageType } from '@/types'
import React from 'react'
import { Button } from '@/components/UI/Button/Button'
import DOMPurify from 'isomorphic-dompurify'
import { RenderCmsImage } from '@/components/UI/RenderCmsImage/RenderCmsImage'

interface TileProps {
    id: string
    value: {
        id: string
        title: string
        links: CallToAction[]
        image: ImageType
        body: string
    }
}

interface TilesBlockProps {
    id: string
    value: {
        title: string
        tiles: TileProps[]
    }
}

export const TilesBlock = ({value}: TilesBlockProps) => {
    const { tiles = [] } = value

    return (
        <div className="grid grid-cols-3 gap-8 py-10 lg:py-20">
            <h2 className="h4 col-span-full">{value.title}</h2>

            {tiles.map((tile, index) => {
                const { id, title, links = [], image, body } = tile.value
                const key = `${ id }${ index }`

                return (
                    <div key={ key } className="col-span-full flex flex-col gap-6 lg:col-auto">
                        <div className="relative h-[400px] w-full">
                            <RenderCmsImage
                                src={ image.large.src }
                                alt={ image.alt }
                                style={{ objectFit: 'contain', objectPosition: 'left' }}
                                fill
                                sizes="(max-width: 1024px) 100vw, 33vw"
                            />
                        </div>
                    
                        <div className="flex flex-col gap-2">
                            <h3 className="headline">{title}</h3>
                        
                            <div className="richText" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }} />
                        </div>

                        {links.map((link) => {
                            return (
                                <Button
                                    className="self-start"
                                    key={ link.url }
                                    onClick={ link.url }
                                    openInNewTab={ link.openInNewTab }
                                    withBorder
                                    ariaLabel={ link.ariaLabel }
                                >
                                    {link.label}
                                </Button>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    )
}
