import RenderComponents from '@/components/RenderComponents'
import { BlockType } from '@/types'
import classNames from 'classnames'
import DOMPurify from 'isomorphic-dompurify'

interface RichTextBlockProps {
    value: {
        first: string
        last: BlockType[]
    }
}

export const TwoColumnBlock = ({ value }: RichTextBlockProps) => {
    const { first, last } = value
    return (
        <div className="grid grid-cols-12 gap-8 pt-20">
            <div className={ classNames('richText col-span-full lg:col-span-5') } dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(first) }} />
            
            <div className={ classNames('richText col-span-full lg:col-start-7 lg:col-span-6') }>
                <RenderComponents isNested content={ last } />
            </div>
        </div>
    )
}
