import { CMSQueries, NotFoundContents } from 'wagtail-js'
import Cookies from 'js-cookie'
import { getMockPage } from '@/mock/mocks'
import { MenuData, PageTypes, WagtailPageType } from '@/types'
import type { HttpStatusCode } from './HttpStatusCodes'

const isProduction = process.env.NODE_ENV === 'production'
export const PRIVATE_BASE_URL = process.env.NEXT_PRIVATE_BASE_URL || ''
const WAGTAIL_API_PAGES_WITH_PATH = '/api/v2/pages/with-path/' // See detail_view_by_path function in /routers/wagtail_api.py
const WAGTAIL_API_PAGES = '/api/v2/pages/'
const WAGTAIL_API = '/api/v2/'
const APP_API_URL = '/api/platform/v1'
// const WAGTAIL_API_PAGES_FIND = '/api/v2/pages/find/';

interface ApiResponse {
    code: HttpStatusCode
    success: boolean
    data?: any
    errors?: Array<Record<string, string>>
}

export class ApiError extends Error {
    code: ApiResponse['code'] | 'ERR_INTERNET_DISCONNECTED'
    raw: ApiResponse['errors']
    errors: Record<string, string>

    constructor(json: ApiResponse) {
        super()
        this.name = this.constructor.name
        this.code = json.code || 500
        this.raw = json.errors
        this.errors = {}

        if (Array.isArray(json.errors)) {
            this.errors = json.errors.reduce((accumulator, error) => ({
                ...accumulator,
                ...error,
            }), {})
        }

        // console.error(json);
        // console.error(`${this.name} ${this.code} ${JSON.stringify(this.raw)}`)
    }
}

export const processAPIResponse = async <T = any>(response: Response): Promise<T> => {
    const json: ApiResponse = await response.json()
    const { data, success } = json

    if (success && data) {
        if (Array.isArray(data)) {
            // @ts-ignore
            return data
        }
        return {
            ...data,
        }
    }

    throw new ApiError(json)
}

export function publicFetchGet(url: string) {
    let Cookie = ''

    if (typeof window === 'undefined') {
        const cs = require('next/headers').cookies()
        Cookie = `csrftoken=${ cs.get('csrftoken')?.value };sessionid=${ cs.get('sessionid')?.value }`
    } else {
        const cookieStore = Cookies
        Cookie = `csrftoken=${ cookieStore.get('csrftoken')?.value };sessionid=${ cookieStore.get('sessionid')?.value }`
    }

    return fetch(url, {
        method: 'GET',
        // cache: 'no-cache',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Cookie,
        },
        next: {
            revalidate: 60, // 60 seconds
        },
    })
}

export function publicFetchPost(url: string, data: any) {
    // const cs = require('next/headers').cookies();

    return fetch(url, {
        method: 'POST',
        // cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
        },
        body: JSON.stringify(data),
    })
}

export function authenticatedFetchGet(url: string) {
    return fetch(`${ APP_API_URL }${ url }`, {
        method: 'GET',
        // cache: 'no-cache',
        credentials: 'include',
        // headers: {
        //     Accept: 'application/json',
        // Cookie: `csrftoken=${ csrfToken };sessionid=${ sessionid }`,
        // },
        // redirect: 'follow', // Needed if we take the PAGES_FIND approach
    })
}

export function authenticatedFetchPut(url: string, data: any) {
    let csrftoken = ''
    if (typeof window === 'undefined') {
        const cs = require('next/headers').cookies()
        csrftoken = cs.get('csrftoken')?.value
    } else {
        csrftoken = Cookies.get('csrftoken')
    }

    return fetch(`${ APP_API_URL }${ url }`, {
        method: 'PUT',
        body: JSON.stringify(data),
        // cache: 'no-cache',
        credentials: 'include',
        headers: {
            'X-CSRFToken': csrftoken,
        },
    })
}

export function authenticatedFetchPost(url: string, data: any, platform: boolean = true) {
    // const cs = require('next/headers').cookies();

    return fetch(`${ platform ? `${ APP_API_URL }` : `${ WAGTAIL_API }` }${ url }`, {
        method: 'POST',
        body: JSON.stringify(data),
        // cache: 'no-cache',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken'),
        },
    })
}

// Use for retrieve Wagtail page data
function createPageUrl(pathname: string, queries: CMSQueries, wagtail_withpath: boolean = true): string {
    const queryParams = new URLSearchParams(queries as Record<string, string>)
    const pn = (pathname === '' || pathname === '/') ? '' : `${ pathname }/`
    const wagtail_api_url = (wagtail_withpath) ? WAGTAIL_API_PAGES_WITH_PATH : WAGTAIL_API_PAGES
    return `${ PRIVATE_BASE_URL }${ wagtail_api_url }${ pn }?${ queryParams.toString() }`
}

// Use for retrieve other Wagtail model data such as snippet or menus
function createModelUrl(pathname: string): string {
    return `${ PRIVATE_BASE_URL }${ WAGTAIL_API }${ pathname }`
}

/**
 * Takes an object, and recursively goes through it replacing any nginx links with the
 * appropriate SiteSafe base URL.
 *
 * `http://nginx` will be replaced with `process.env.NEXT_PUBLIC_BASE_URL || 'http://sitesafe.localhost'`
 */
// function restoreURLs<T>(data: T): T {
//     const dataAsString = JSON.stringify(data)
//     return JSON.parse(dataAsString.replaceAll(NGINX_URL, BASE_URL))
// }

export interface GetPageProps {
    pathname: string[] // ['/'], ['about'], ['about', 'news'], ['a', 'b', 'c']
    inPreviewPanel: boolean,
    token?: string
    contentType?: string
    pageType: PageTypes
}

/**
 * getPage fetches a page type from Wagtail given the pathname and pageType.
 *
 * @param inPreviewPanel Enables preview flags to be passed to the Wagtail API
 * @param pageType is the type of page model declared in Wagtail - `app.HomePage | app.StandardPage | app.CoursePage`
 */
export async function getPage<PageType extends WagtailPageType>({ pathname = [], inPreviewPanel, token, contentType }: GetPageProps): Promise<PageType | null> {
    // Ignore anything that isn't a page
    if (['api', '_next', 'media', 'videos'].includes(pathname[0])) {
        return null
    }

    const completePathName = pathname.join('/')

    const queries: CMSQueries = {
        fields: ['*'], // Return all https://docs.wagtail.org/en/stable/advanced_topics/api/v2/usage.html#all-fields
        // pageType,
    }

    if (inPreviewPanel) {
        queries.in_preview_panel = true
        queries.draft = true
    }
    if (token) {
        queries.token = token
    }
    if (contentType) {
        queries.content_type = contentType
    }

    const url = createPageUrl(completePathName, queries)
    // console.log('[api.ts getPage]', url)

    const response = await publicFetchGet(url)
    const page = await response.json()

    // Wagtail might not contain the page so attempt to return a mock
    if ((page as NotFoundContents).message === 'No Page matches the given query.') {
        if (!isProduction) {
            console.log(`🟧 Attempting to return a mock for "/${ completePathName }"`)
            return getMockPage<PageType>(completePathName)
        }
    }

    if (response.ok) {
        // console.log('🟩 Successfully fetched page from Wagtail', JSON.stringify(page))
        return page as PageType
    }

    // console.log(`🟥 Failed to fetch page from Wagtail for "/${ completePathName }"`)
    return null
}

export const fetchMenu = async (): Promise<MenuData> => {
    const menuUrl = createModelUrl('menus')

    // console.log("[api.ts fetchMenu]", menuUrl)

    const response = await publicFetchGet(menuUrl)
    const data = await response.json()

    // console.log('🟩 Successfully fetched menu from Wagtail', JSON.stringify(data))

    return data
}

/*
 * fetchModelData method is used for providing data via a collection of /api/v2/{path_name}/ that we have created in wagtail_api.py
 * Eg: /api/v2/menus or /api/v2/news-categories
 *
 * The purpose of this method is retrieving data that we have setup for other Models, not just Pages
 * Eg: Snippets, Menus, Settings, etc
 */
// @ts-ignore
export async function fetchModelData({ pathname }) {
    try {
        const url = createModelUrl(pathname)
        // console.log(`⬜ Fetching data for ${ pathname }'\n'${ url }'`)
        const response = await publicFetchGet(url)
        const data = await response.json()
        if ((data as NotFoundContents).message) {
            throw new Error('Model or Pathname not found.')
        }
        // console.log('🟩 Successfully fetched data from Wagtail', data)
        return data
    } catch (err) {
        console.log(err)
    }
}
