import { WagtailPageType } from "@/types"

export const mockAboutPage: WagtailPageType = {
    id: 1,
    title: 'Placeholder about page',
    meta: {
        slug: '/about/',
        type: 'app.StandardPage',
        locale: '',
        html_url: '',
        detail_url: '',
    },
    content: [
        {
            type: 'RichText',
            value: "<h1>About the project</h1>",
        },
        {
            type: 'TwoColumnBlock',
            value: {
                first: "<h2>People</h2>",
                last: [
                    {
                        type: 'RichText',
                        value: "<p><strong>Amanda Yates</strong></p><p>Associate Professor Amanda Monehu Yates Ngāti Whakaue, Ngāti Rangiwewehi, Te Aitanga a Mahakai, Rongowhakaataworks with Councils, Iwi, and communities exploring place-based indigenous-led strategies and actions for holistic urban wellbeing in an era of climate and biodiversity emergency. Amanda is an Associate Professor and director of He Puna Ora, the Regenerative Urbanism Wellbeing Lab at AUT’s Huri te Ao, the School of Future Environments. She is Programme Leader for Huritanga, the Urban Wellbeing programme funded by the National Science Building Better Homes Towns and Cities Challenge.</p>",
                    },     
                ],
            },
        },
        {
            type: 'TwoColumnBlock',
            value: {
                first: "<h2>Data methodology</h2>",
                last: [
                    {
                        type: 'RichText',
                        value: "<p>The methodology developed for the Huritanga Data Tool is intended to be a first step towards creating a more robust performance monitoring regime. Data limitations require that this method is presented as a proof of concept and that new pathways are developed for the future enhancement of the method. The numbers presented in the data tool are primarily intended to be educational and provide heuristic guidance rather than representing an absolute performance measure. </p><p>A series of mathematical formulas are used to establish baseline performance scores, set future performance targets, and evaluate current performance against future targets.</p><p>Indicator scoring logic is included within the insights panel for each indicator.</p>",
                    },   
                    {
                        type: "AccordionBlock",
                        value: {
                            items: [
                                {
                                    title: 'Full methodology',
                                    body: '<p>Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum.</p>',
                                },
                                {
                                    title: 'Linear regression for baseline trend',
                                    body: '<p>Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la imprenta) desconocido usó una galería de textos y los mezcló de tal manera que logró hacer un libro de textos especimen. No sólo sobrevivió 500 años, sino que tambien ingresó como texto de relleno en documentos electrónicos, quedando esencialmente igual al original. Fue popularizado en los 60s con la creación de las hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y más recientemente con software de autoedición, como por ejemplo Aldus PageMaker, el cual incluye versiones de Lorem Ipsum.</p>',
                                },
                            ],
                        },
                    },  
                ],
            },
        },
    ],
}
