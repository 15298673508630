import { MenuData } from '@/types'
import { mockChristchurchLivingCitiesPage } from './mockPages/christchurch-living-cities'
import { mockChristchurchDataPage } from './mockPages/christchurch-data'
import { mockAboutPage } from './mockPages/about'
import { mockChristchurchStoryPage } from './mockPages/christchurch-story'

const MOCK_PAGES = {
    'christchurch/living-cities': mockChristchurchLivingCitiesPage,
    'christchurch/data': mockChristchurchDataPage,
    'christchurch': mockChristchurchStoryPage,
    'about': mockAboutPage,
}

/**
 * `getMockPage` will take the full pathname and attempt to return a mock wagtail page
 * from the folder `mockPages`.
 */
export async function getMockPage<PageType>(pathname: string): Promise<PageType | null> {
    const mockPage: PageType = MOCK_PAGES[pathname]

    if (!mockPage) {
        console.error(`🟥 No mock page found for ${ pathname }. Add it to "/src/mock/mockPages/".`)
        return null
    }

    console.log(`✅ Mock found for /${ pathname }`)
    return mockPage
}

export function getMockMenu(): MenuData {
    return {
        id: 1,
        header_menu: [
            {
                type: "Link",
                id: 'christchurch',
                "value": {
                    type: "link",
                    url: '/christchurch/',
                    open_in_new_tab: false,
                    label: 'Compass story',
                },
            },
            {
                type: "Link",
                id: 'christchurch',
                "value": {
                    type: "link",
                    url: '/christchurch/',
                    open_in_new_tab: false,
                    label: 'Living cities',
                },
            },
            {
                type: "Link",
                id: 'christchurch',
                "value": {
                    type: "link",
                    url: '/christchurch/data/',
                    open_in_new_tab: false,
                    label: 'Data compass',
                },
            },
            {
                type: "Link",
                id: 'christchurch',
                "value": {
                    type: "link",
                    url: '/christchurch/',
                    open_in_new_tab: false,
                    label: 'Data compass',
                },
            },
        ],
        footer_menu: [
            {
                type: "Link",
                id: 'christchurch',
                "value": {
                    type: "link",
                    url: '/christchurch/',
                    open_in_new_tab: false,
                    label: 'Christchurch',
                },
            },
        ],
    }
}