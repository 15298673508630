'use client'

import { CompassGraphDataV2, Indicator, WagtailMetaType, WagtailPageType } from '@/types'
import React from 'react'
// import Image from 'next/image'
import { OverviewPanel } from './_components/OverviewPanel/OverviewPanel'
import { IndicatorPanel } from './_components/IndicatorPanel/IndicatorPanel'
import { CompassGraph } from './_components/CompassGraph/CompassGraph'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import classNames from 'classnames'
import { createPortal } from 'react-dom'
import { motion } from 'framer-motion'
import CrossSVG from '@/assets/svg/cross.svg'
import { useMediaQuery } from '@mantine/hooks'
import { PORTAL_ID_FOR_INDICATOR_PANEL } from '@/utils/constants'
import { usePrefersReducedMotion } from '@/utils/usePrefersReducedMotion'
export interface DataCompassPageProps extends WagtailPageType {
    meta: WagtailMetaType
    compassGraphData: CompassGraphDataV2
    compassGroup?: string
    indicator?: string
    location_title: string
    location_title_te_reo?: string
}

// https://he-puna-ora.dev.octave.nz/api/v2/pages/with-path/christchurch/data/?fields=*
export const DataCompassPage = (page: DataCompassPageProps) => {
    const router = useRouter()
    const {
        compassGraphData,
        meta: {
            url: dataCompassPageUrl,
        },
        location_title,
        // location_title_te_reo,
    } = page

    const pathname = usePathname()
    const searchParams = useSearchParams()
    const highlightGroup = searchParams.get('group')

    // Example full pathname /christchurch/mauri-ora/urban/23/
    // First /, "location", "pageUrl", "compassGroup", "indicator"
    const [, , , compassGroup, indicator] = pathname.split("/")

    const prefersReducedMotion = usePrefersReducedMotion()

    const [indicatorPortal, setIndicatorPortal] = React.useState<HTMLElement | null>(null)
    const [selectedIndicator, setSelectedIndicator] = React.useState<{
        compassGroupId?: string
        indicatorId?: string
    }>({
        compassGroupId: compassGroup,
        indicatorId: indicator,
    })

    React.useEffect(() => {
        setIndicatorPortal(document.getElementById(PORTAL_ID_FOR_INDICATOR_PANEL))
    }, [])

    React.useEffect(() => {
        const body = document.querySelector('body')

        if (selectedIndicator.indicatorId) {
            body.classList.add("no-body-scroll")
        } else {
            body.classList.remove("no-body-scroll")
        }
    }, [selectedIndicator.indicatorId])

    const isLarge = useMediaQuery('(min-width: 1024px)')

    const activeCompassIndicators: Indicator[] = compassGraphData[selectedIndicator?.compassGroupId] || []
    const activeIndicator = activeCompassIndicators.find((potentialIndicator) => potentialIndicator.slug === selectedIndicator?.indicatorId)

    const [indicatorHoverId, setIndicatorHoverId] = React.useState<string | null>(null)

    const onSetSelectedIndicator = (props: typeof selectedIndicator) => {
        setSelectedIndicator(props)
        setIndicatorHoverId(null)
    }

    const compassIndicatorIndex = compassGraphData.order.findIndex((order) => order.id === activeIndicator?.compassId)

    return (
        <>
            <div className="isDark relative">
                <div className="relative z-10 grid grid-cols-1 md:min-h-screen lg:mt-[-64px] lg:grid-cols-[auto_10%_30%] lg:pt-0">
                    {/* Radial gradient only active when compass segment is selected */}
                    {/* <div className="w-[120%] h-[120%] opacity-60 bg-darkGreen-100/opacity-30 rounded-full" /> */}
                    {/* </div> */}

                    {/* Compass tool */}
                    <div id="graphDrawArea" className={ classNames("pointer-events-none hover:cursor-grab active:cursor-grabbing lg:pointer-events-auto block lg:sticky lg:top-2 lg:row-start-1 lg:col-start-1 bg-transparent lg:flex items-center justify-center lg:h-[calc(100vh_-_16px_-_64px)] mx-4 mb-4 mg-4 lg:mt-16", activeIndicator ? "lg:col-end-3" : "lg:col-end-3") }
                        onClick={ () => {
                        // Close the indicator if you click anywhere that isn't the CompassGraph indicators
                            onSetSelectedIndicator({
                                compassGroupId: null,
                                indicatorId: null,
                            })
                            router.push(dataCompassPageUrl, { scroll: false })
                        } }
                    >
                        <CompassGraph
                            data={ compassGraphData }
                            dataCompassPageUrl={ dataCompassPageUrl }
                            activeCompassGroupId={ selectedIndicator?.compassGroupId }
                            activeIndicatorId={ selectedIndicator?.indicatorId }
                            indicatorHoverId={ indicatorHoverId }
                            setSelectedIndicator={ onSetSelectedIndicator }
                        />
                    </div>

                    {/* Aside - overview */}
                    <div className={ classNames("col-span-full row-start-2 lg:row-start-1 lg:col-start-3 relative") }>
                        <div className="px-6 pb-20 pt-10 md:px-8 lg:pb-40 lg:pl-0 lg:pr-12 lg:pt-20 ">
                            <OverviewPanel
                                location={ location_title }
                                // location_title_te_reo={ location_title_te_reo }
                                dataCompassPageUrl={ dataCompassPageUrl }
                                data={ compassGraphData }
                                onIndicatorHover={ (indicatorId) => setIndicatorHoverId(indicatorId) }    
                                setSelectedIndicator={ onSetSelectedIndicator }
                                highlightGroup={ highlightGroup }
                            />
                        </div> 
                    </div>
                </div>

                {/* Background patterns */}
                {/* @TODO Could make it sticky so it's fixed on the page, and doesn't grow when you open accordions, mobile will need to do something different though */}
                {/* <div className="hidden lg:block absolute inset-0 -top-[64px] overflow-hidden">
                    <Image className="object-cover h-full w-full lg:hidden" src="/images/data-tool/bg-pattern/bg-pattern-phone.png" width={ 430 } height={ 932 } alt="" />
                    <Image className="object-cover h-full w-full hidden lg:block 2xl:hidden" src="/images/data-tool/bg-pattern/bg-pattern-1024.png" width={ 1024 } height={ 1035 } alt="" />
                    <Image className="object-cover h-full w-full hidden 2xl:block" src="/images/data-tool/bg-pattern/bg-pattern-1440.png" width={ 1440 } height={ 1035 } alt="" />
                </div> */}
            </div>

            {/* Sheet style at mobile/tablet, right hand panel at desktop and up */}
            {indicatorPortal && createPortal(
                <motion.div className="fixed inset-x-0 bottom-0 top-4 z-20 overflow-hidden lg:left-auto lg:top-0"
                    animate={ activeIndicator ? "open" : "closed" }
                    initial="closed"
                    variants={{
                        open: {
                            y: '0%',
                            x: isLarge ? '0%' : '0%',
                            transition: {
                                y: {
                                    type: prefersReducedMotion ? 'just' : 'spring',
                                    duration: prefersReducedMotion ? 0 : 0.5,
                                },
                                x: {
                                    type: prefersReducedMotion ? 'just' : 'spring',
                                    duration: prefersReducedMotion ? 0 : 0.5,
                                },
                            },
                        },
                        closed: {
                            y: isLarge ? '0%' : '100%',
                            x: isLarge ? '100%' : '0%',
                        },
                    }}
                >
                    <div className="z-30 h-full overflow-y-auto rounded-t-2xl bg-darkGreen-100 lg:rounded-none lg:bg-darkGreen-100/80 lg:backdrop-blur-xl">
                        <div className="h-full lg:ml-auto lg:max-w-[600px] 2xl:w-[33vw] 2xl:max-w-full">
                            <IndicatorPanel
                                indicator={ activeIndicator }
                                compassGroupIndicators={ activeCompassIndicators }
                                compassIndex={ compassIndicatorIndex }
                            />
                        </div>

                        <motion.button
                            aria-label="Close indicator panel"
                            className="fixed right-2 top-2 z-40 size-12 rounded-full border-2 border-lightGreen  bg-darkGreen-100/80 p-1 text-lightGreen backdrop-blur-sm hover:border-ecology hover:text-ecology lg:hidden"
                            onClick={ () => setSelectedIndicator({
                                compassGroupId: null,
                                indicatorId: null,
                            }) }
                            animate={ activeIndicator ? "open" : "closed" }
                            initial="closed"
                            variants={{
                                open: {
                                    opacity: 1,
                                },
                                closed: {
                                    opacity: 0,
                                    transition: {
                                        opacity: {
                                            duration: 0,
                                        },
                                    },
                                },
                            }}
                        >
                            <CrossSVG />
                        </motion.button>
                    </div>
                </motion.div>,
                indicatorPortal
            )}
        </>
    )
}
